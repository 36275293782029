<template>
  <div>
    <div class="Lfloat" ref="Lfloat">
      <h2>{{ floatList.title }}</h2>
      <div class="">
        <p
          v-for="(item, index) in floatList.list"
          :key="index"
          @click="goProject(item.url)"
          class="Lfloat-list"
        >
          <!-- :style="item.url == 'flush' ? {background:'url(' + require('~static/scenery/l-borderActive.png') + ')'} : ''" -->
          {{ item.name }}
        </p>
      </div>
    </div>
  </div>
  <!-- width: 122px;
    height: 270px;
    margin: 0 auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around; -->
</template>

<script>
export default {
  name: "TznmhleftFloat",
  props: {
    floatList: {},
    required: true,
  },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },

  methods: {
    goProject(src) {
      if(src == 'https://shop565612588.taobao.com/'){
        window.open(src, "_blank");
      }else if(src == 'flush'){
        this.$router.go(0)
      }else{
        this.$router.push(src)
      }
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 270) {
        this.$refs.Lfloat.style.top = 653 - scrollTop + "px";
      } else {
        this.$refs.Lfloat.style.top = "30%";
      }
      if (scrollTop > 3500) {
        this.$emit("showMap");
      }
      // console.log(scrollTop);
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>

<style scoped>
.Lfloat {
  width: 352px;
  height: 527px;
  background-image: url("~static/flotbg2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  left: 0;
  top: 653px;
  z-index: 99;
  font-family: 'tzn-font-pmzd';
}
.Lfloat > div {
  width: 122px;
  height: 235px;
  margin: 0 auto;
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.Lfloat > h2 {
  width: 120px;
  position: relative;
  top: 23%;
  margin: 22px auto 0;
  font-size: 24px;
  font-weight: 500;
  color: #5098af;
  line-height: 30px;
}

.Lfloat-list {
  /* width: 128px;
  height: 30px;
  margin: 22px auto;
  position: relative;
  top: 25%; */
  background-image: url("~static/scenery/l-border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 18px;
  color: #5098af;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
</style>